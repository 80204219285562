<template>
    <div class="container">
        <h1 class="mt-5">Garfong AB</h1>
        <img class="mt-3" src="bild.jpg" />
        <div class="mt-3">
            Mitt namn är Henrik Borg och jag är IT-konsult med specialkompetens inom Individuell Mätning och Debitering (IMD). Kontakta mig med formuläret nedan.
        </div>
        <div v-if="showForm" id="form">
            <div class="mb-3 mt-5">
                <label for="emailInput" class="form-label">Din e-postadress</label>
                <input type="email" class="form-control" id="emailInput" placeholder="namn@example.se" v-model="state.email" />
                <div v-if="v$.email.$error" class="small" style="color: red">Vänligen ange en korrekt e-postadress</div>
            </div>
            <div class="mb-3">
                <label for="messageTextarea" class="form-label">Meddelande till mig</label>
                <textarea class="form-control" id="messageTextarea" rows="3" v-model="state.message"></textarea>
                <div v-if="v$.message.$error" class="small" style="color: red">Vänligen skriv ett meddelande</div>
            </div>
            <div class="form-group">
                <label for="emailInput" class="form-label">Skriv texten i rutan nedanför</label>
                <div>
                    <div id="captchaImage"></div>
                    <div style="width: 150px"><input class="form-control" id="captchaInput" type="text" v-model="state.captcha" /></div>
                </div>
                <div v-if="v$.captcha.$error" class="small" style="color: red">Vänligen verifiera att detta inte är en robot</div>
            </div>
            <div class="form-group mt-4">
                <button class="btn btn-secondary mb-3" @click="sendMessage()">Skicka meddelande</button>
            </div>
        </div>
        <div v-if="success" class="alert alert-success mt-3">
            <strong>Tack för ditt meddalnde!</strong> Jag återkommer till dig så snart jag kan.
        </div>
        <div v-if="failure" class="alert alert-danger">
            <strong>Det blev fel!</strong> Texten överensstämmer inte med den i bilden.
        </div>
    </div>
</template>
<script>
import api from './api.js'
import { required, email } from '@vuelidate/validators'
import { reactive, computed } from 'vue'
import useVuelidate from '@vuelidate/core'

export default {
    setup() {
        const state = reactive({
            email: '',
            message: '',
            captcha: ''
        })

        const rules = computed(() => {
            return {
                email: { required, email },
                message: { required },
                captcha: { required }
            }
        })

        const v$ = useVuelidate(rules, state)

        return { state, v$ }
    },
    data() {
        return {
            captchaHash: null,
            showForm: true,
            success: false,
            failure: false,
            counter: null
        }
    },
    methods: {
        sendMessage() {
            this.v$.$validate()
            if (!this.v$.$error) {
                api.postSendMessage(
                    this.state.email,
                    this.state.message,
                    this.state.captcha,
                    this.captchaHash
                ).then(() => {
                    this.success = true
                    this.failure = false
                    this.showForm = false
                }).catch((err) => {
                    this.success = false
                    this.failure = true
                    api.getCaptchaImage().then(res => {
                        this.captchaHash = res.data.hash
                        const captchaImage = document.querySelector('#captchaImage')
                        captchaImage.innerHTML = res.data.image
                    })
                })

            }
        }
    },
    mounted() {
        api.getCount().then(res => {
            this.counter = res.data.count
            console.log(this.counter)
        })
        api.getCaptchaImage().then(res => {
            this.captchaHash = res.data.hash
            const captchaImage = document.querySelector('#captchaImage')
            captchaImage.innerHTML = res.data.image
        })
    }
}
</script>
