import axios from 'axios'

const instance = axios.create({
    //baseURL: 'http://192.168.1.150:4111',
    baseURL: 'https://garfong.com:4113',
    headers: {
        'Content-Type': 'application/json',
    },
})

function getCaptchaImage() {
    return instance.get(`/captcha`)
}

function postSendMessage(email, message, captcha, hash) {
    return instance.post(`/send_message`, { email, message, captcha, hash })
}

function getCount() {
    return instance.get(`/count`)
}

export default {
    getCaptchaImage,
    postSendMessage,
    getCount
}


